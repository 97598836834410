<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.editSpecialMarket') }}</h5>
                <Accordion :activeIndex="0">
                    <AccordionTab :header="$t('markets.titles.marketDetails')">
                        <div class="p-grid">
                            <div class="p-col-12 p-md-6">
                                <div class="p-field p-grid" v-show="this.isEdit()">
                                    <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.id') }}</label>
                                    <div class="p-col-12 p-md-8">
                                        <InputText style="width: 100%" type="text" v-model="market.marketId" disabled />
                                    </div>
                                </div>
                                <div class="p-field p-grid">
                                    <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.displayName') }}</label>
                                    <div class="p-col-12 p-md-8">
                                        <InputText style="width: 100%" type="text" v-model="market.displayName" />
                                    </div>
                                </div>
                                <div class="p-field p-grid">
                                    <label for="displayName" class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.sport') }} *</label>
                                    <div class="p-col-12 p-md-8">
                                        <Dropdown style="width: 100%" v-model="sportsModel" :options="sports" optionLabel="displayName" :placeholder="$t('dropdown.placeholder.sport')"  :filter="!this.isMobile()"  />
                                    </div>
                                </div>
                                <div class="p-field p-grid">
                                    <label for="displayName" class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.template') }} *</label>
                                    <div class="p-col-12 p-md-8">
                                        <Dropdown style="width: 100%" v-model="templateModel" :options="specialTemplates" optionLabel="name" :placeholder="$t('dropdown.placeholder.template')"  :filter="!this.isMobile()"  />
                                    </div>
                                </div>
                                <div class="p-field p-grid">
                                    <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.sortOrder') }}</label>
                                    <div class="p-col-12 p-md-8">
                                        <InputText style="width: 100%" type="text" v-model="market.sortNo" />
                                    </div>
                                </div>
                                <div class="p-field p-grid">
                                    <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.displayColumns') }}</label>
                                    <div class="p-col-12 p-md-8">
                                        <InputText style="width: 100%" type="text" v-model="market.displayCols" />
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-6"></div>
                        </div>
                    </AccordionTab>
                    <AccordionTab :header="$t('markets.titles.marketOutcomes')">
                        <div class="p-col-12" v-show="market.outcomes != null && market.outcomes != []">
                            <div class="card p-grid">
                                <div class="p-col-12 p-md-2 p-text-center">
                                    <h6>{{ $t('markets.specialMarkets.hasSpecifier') }}</h6>
                                    <InputSwitch v-model="market.hasSpecifier" />
                                </div>
                                <div class="p-col-12 p-md-2 p-text-center">
                                    <h6>{{ $t('markets.specialMarkets.hasPlayers') }}</h6>
                                    <InputSwitch v-model="market.hasPlayers" />
                                </div>
                                <div class="p-col-12 p-md-2 p-text-center" v-show="market.hasSpecifier">
                                    <h6>{{ $t('markets.specialMarkets.specifierFirst') }}</h6>
                                    <InputSwitch v-model="specifierFirst" />
                                </div>
                                <div v-for="(outcome, index) in market.outcomes" :key="market.id + '_' + index + '_' + outcome.id" class="p-col-12 p-grid">
                                    <div class="p-field p-grid p-col-12 p-md-3">
                                        <label for="outcomeId" class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.id') }}</label>
                                        <div class="p-col-12 p-md-8">
                                            <InputText style="width: 100%" type="text" v-model="outcome.outcomeId" />
                                        </div>
                                    </div>
                                    <div class="p-field p-grid p-col-12 p-md-3">
                                        <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.displayName') }}</label>
                                        <div class="p-col-12 p-md-8">
                                            <InputText style="width: 100%" type="text" v-model="outcome.name" />
                                        </div>
                                    </div>
                                    <div class="p-field p-grid p-col-12 p-md-3">
                                        <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.outcomeKey') }}</label>
                                        <div class="p-col-12 p-md-8">
                                            <InputText style="width: 100%" type="text" v-model="outcome.code" />
                                        </div>
                                    </div>
                                    <div class="p-field p-grid p-col-12 p-md-3">
                                        <label class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">{{ $t('sportsSettings.sortOrder') }}</label>
                                        <div class="p-col-12 p-md-8">
                                            <InputText style="width: 100%" type="text" v-model="outcome.sortNo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AccordionTab>
                    <AccordionTab :header="$t('markets.titles.marketGroups')">
                        <div class="card">
                            <div class="p-grid">
                                <div class="p-col-6 p-md-2" v-for="(group, index) in groups" :key="group.name + '_' + index">
                                    <div class="p-field-checkbox">
                                        <Checkbox :id="'checkOption' + index" :value="group.name" v-model="selectedGroups" />
                                        <label :for="'checkOption' + index">{{ group.name }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>
                <transition-group name="p-message" tag="div">
                    <Message :key="errorKeyCount" :severity="'error'" v-show="showError">{{ errorText }}</Message>
                </transition-group>
                <div class="p-col-12">
                    <Button @click="submitForm()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                    <Button :label="$t('buttons.cancel')" class="p-button-raised p-button-danger p-mr-2 p-mb-2" @click="goto(backRoute)" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { SportsSettingsApi } from '../../service/SportSettingsApi';
export default {
    data() {
        return {
            SportsSettingsApi: new SportsSettingsApi(),
            market: { outcomes: [] },
            showError: false,
            errorText: '',
            disableSubmit: false,
            backRoute: 'specialMarkets',
            errorKeyCount: 0,
            selectedGroups: [],
            showInfo: true,
            groups: [],
            sports: [],
            sportsModel: {},
            specialTemplates: [],
            templateModel: [],
            specifierFirst: false,
            awaitSports: true,
            awaitTemplates: true,
        };
    },
    computed: {
        HasSpecifier() {
            return this.market.hasSpecifier;
        },
        HasPlayers() {
            return this.market.hasPlayers;
        },
    },
    watch: {
        templateModel() {
            this.GetMarketOutcomes();
        },
        HasSpecifier() {
            this.GetMarketOutcomes();
        },
        specifierFirst() {
            this.GetMarketOutcomes();
        },
        HasPlayers() {
            this.GetMarketOutcomes();
        },
    },
    mounted() {
        this.getSports();
        this.getSpecialMarketsTemplates();
        this.SportsSettingsApi.getAllGroups()
            .then((responseGroups) => {
                this.groups = responseGroups.data;
                if (this.isEdit()) {
                    this.SportsSettingsApi.getSpecialMarket(this.$route.params.id).then((response) => {
                        this.market = response.data;
                        for (var i = 0; i < this.groups.length; i++) {
                            if (this.market.groups.indexOf(this.groups[i].name) >= 0) {
                                this.selectedGroups.push(this.groups[i].name);
                            }
                        }
                        // while (this.awaitTemplates || this.awaitSports);
                        this.templateModel = this.specialTemplates.find((t) => t._id == this.market.templateId);
                        this.sportsModel = this.sports.find((t) => t.id == this.market.sportId);
                    });
                }
            })
            .catch((error) => {
                this.errorText = error;
                this.showError = true;
                this.errorKeyCount++;
            });
    },
    methods: {
        GetMarketOutcomes() {
            var outcomes = [];
            for (var i = 0; i < this.templateModel.outcomes.length; i++) {
                var marketOutcome = {
                    outcomeId: this.templateModel.outcomes[i].outcomeId,
                    keyCode: this.market.outcomes.length > i ? this.market.outcomes[i].keyCode : '',
                    sortNo: this.market.outcomes.length > i ? this.market.outcomes[i].sortNo : 0,
                    name: '',
                };
                var dynamicText = this.market.hasPlayers ? '{player}' : '{specifier}';
                if (this.market.hasSpecifier) {
                    marketOutcome.name = this.specifierFirst ? dynamicText + ' ' + this.templateModel.outcomes[i].name : this.templateModel.outcomes[i].name + ' ' + dynamicText;
                } else {
                    marketOutcome.name = this.templateModel.outcomes[i].name;
                }
                outcomes.push(marketOutcome);
            }
            this.market.outcomes = outcomes;
        },
        submitForm() {
            this.disableSubmit = true;
            this.errorText = '';
            this.market.groups = this.selectedGroups;
            this.market.templateId = this.templateModel._id;
            this.market.sportId = this.sportsModel.id;
            this.SportsSettingsApi.updateSpecialMarket(this.market)
                .then(() => {
                    this.goto(this.backRoute);
                })
                .catch((error) => {
                    this.disableSubmit = false;
                    this.errorText = error;
                    this.showError = true;
                });
        },
        isEdit() {
            return this.$route.params.id !== '0';
        },
        getOutcomeClass(displayCols) {
            return 'p-mb-2 p-col-12 p-md-' + 12 / displayCols;
        },
        getSports() {
            this.SportsSettingsApi.getAllSports().then((response) => {
                this.sports = response.data;
                this.awaitSports = false;
            });
        },
        getSpecialMarketsTemplates() {
            this.SportsSettingsApi.getSpecialMarketsTemplates().then((response) => {
                this.specialTemplates = response.data;
                this.awaitTemplates = false;
            });
        },
    },
};
</script>
